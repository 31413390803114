import AppRoutes from "./routes";
import ErrorBoundary from "./errorboundary";
import { useEffect, useState } from "react";
import MobileAppRoutes from "./mobileRoutes";

export default function App() {

    const [isMobileView,setIsMobileView] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 700) {
            setIsMobileView(true);
        }
    },[window.innerWidth])

    return (
        <ErrorBoundary>
            {!isMobileView && <AppRoutes />}
            {isMobileView && <MobileAppRoutes/> }
        </ErrorBoundary>
    );
}