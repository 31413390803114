import Footer from "./components/footer";
import Navbar from "./components/navbar";

export default function AppLayout({children}){
    return(
        <section className="layout">
            <Navbar/>
            {
                children
            }
            <Footer/>
        </section>
    );
}