import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Loader from "./components/loader";
import UserLocation from "./containers/location";
import MobileLayout from "./mobile_layout";

const PreLoad = lazy(() => import("./containers/index"));
const Home = lazy(() => import("./containers/mobile_components/home"));
const Projects = lazy(() => import("./containers/project"));
const Contact = lazy(() => import("./containers/contact"));
const About = lazy(() => import("./containers/about"));
const Services = lazy(() => import("./containers/services"));

const MobileAppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<div className="text-center"><Loader /></div>}>
        <Routes>
        <Route
            path="/"
            element={
              <MobileLayout>
                <PreLoad />
              </MobileLayout>
            }
          />
          <Route
            path="/home"
            element={
              <MobileLayout>
                <Home/>
              </MobileLayout>
            }
          />
          <Route
            path="/Projects"
            element={
              <MobileLayout>
                <Projects/>
              </MobileLayout>
            }/>
          <Route
            path="/Contact"
            element={
              <MobileLayout>
                <Contact/>
              </MobileLayout>
            }/>
          <Route
            path="/Services"
            element={
              <MobileLayout>
                <Services/>
              </MobileLayout>
            }/>
          <Route
            path="/About Us"
            element={
              <MobileLayout>
                <About />
              </MobileLayout>
            }/>
          <Route
            path="/privacy-policy"
            element={
              <MobileLayout>
                <Loader />
              </MobileLayout>
            }/>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default MobileAppRoutes;
