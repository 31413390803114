import { useState } from "react"
import { useNavigate } from "react-router-dom";

export default function Navbar({activeElement}){

    const navigate = useNavigate();

    const [navitems, setNavitems] = useState(['Home','Services', 'Projects', 'About Us', 'Contact']);

    const handleNavItemClick = (item) => {
      
        navigate(`/${item}`)
    }

    return(
        <nav className="navbar-container">
            <div className="navbar">
                <img className='logo' src='/assets/logonew.png'/>
                <menu className="nav-items">
            {navitems?.map((item,index)=>(
                <li className="nav-item" onClick={() => handleNavItemClick(item)} key={index}>
                    {item}
                </li>
            ))}
            </menu>
            </div>
        </nav>
    );
}