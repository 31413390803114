import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './mdcomponents.scss'; 
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

export default function Navbar() {
    const navigate = useNavigate();
    const [navitems] = useState(['Home', 'Services', 'Projects', 'About Us', 'Contact']);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleNavItemClick = (item) => {
        setMenuOpen(false);
        navigate(`/${item}`);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className="md-navbar-container">
            <div className="navbar">
                <img className={`logo`} src='/assets/logonew.png' alt="icon engineering construction and consultancy Logo" />
                {!menuOpen &&
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>}
                {menuOpen && <span onClick={toggleMenu} className="hamburger close">&#x274c;</span> }
            </div>
            <menu className={`nav-items ${menuOpen ? 'open' : ''}`}>
                {navitems?.map((item, index) => (
                    <li className="nav-item" onClick={() => handleNavItemClick(item)} key={index}>
                        <span>{item}</span> <span><ChevronRightOutlinedIcon/> </span>
                    </li>
                ))}
            </menu>
        </nav>
    );
}
