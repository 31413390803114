import './mdcomponents.scss';

export default function Footer() {
    return (
        <footer className="md-footer-container">
            <div className="footer">
                <div className="left">
                    <address className='address'>
                        SJK Compound, Opp Jerome Villa,<br />
                        Mohili Village, Sakinaka,<br />
                        Mumbai, 400 072.<br />
                        <a href="tel:9995559990">Tel: 9995559990</a>
                        <a href="mailto:info@iconecc.co.in">Email: info@iconecc.co.in</a>
                    </address>
                </div>
                <div className="right">
                    <nav>
                        <ul className='nav-items'>
                            <li><a href="/about us">About us</a></li>
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <figure className='img-container'>
                <img src="/assets/logonew.png" alt="iconecc logo" />
            </figure>
            <p className='copyright'>
                <small>© 2024 i-CON Engineering Construction and Consultants</small>
            </p>
        </footer>
    );
}
