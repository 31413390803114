export const getSubDomain = () => {
    const parts = window.location.hostname.split('.');
    if (parts.includes('localhost')) {
      return parts.length > 1 && parts[0].toLowerCase() !== 'www' ? parts[0] : null;
    }
    else if (parts.length <= 2 || parts[0].toLowerCase() === 'www') {
      return null;
    } else {
      return parts[0];
    }
  }
  
  export const hasSubdomain = () => {
    const subdomain = getSubDomain();
    return subdomain;
  };