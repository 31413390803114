import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AppLayout from './applayout';
import Loader from "./components/loader";

const PreLoad = lazy(() => import("./containers/index"));
const Home = lazy(() => import("./containers/home"));
const Services = lazy(() => import("./containers/services"));
const Projects = lazy(() => import("./containers/project"));
const Contact = lazy(() => import("./containers/contact"));

const Peload2 = lazy(() => import("./containers/preload2"));
const About = lazy(() => import("./containers/about"));

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<div className="text-center"><Loader /></div>}>
        <Routes>
          <Route
            path="/"
            element={
              <AppLayout>
                <PreLoad />
              </AppLayout>
            }
          />
          <Route
            path="/home"
            element={
              <AppLayout>
                <Home/>
              </AppLayout>
            }
          />
          <Route
            path="/Projects"
            element={
              <AppLayout>
                <Projects/>
              </AppLayout>
            }/>
          <Route
            path="/Contact"
            element={
              <AppLayout>
                <Contact/>
              </AppLayout>
            }/>
          <Route
            path="/Services"
            element={
              <AppLayout>
                <Services />
              </AppLayout>
            }/>
          <Route
            path="/About Us"
            element={
              <AppLayout>
                <About />
              </AppLayout>
            }/>
          <Route
            path="/privacy-policy"
            element={
              <AppLayout>
                <Loader />
              </AppLayout>
            }/>
            <Route
            path="/preload2"
            element={
              <AppLayout>
                <Peload2/>
              </AppLayout>
          }
            />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
