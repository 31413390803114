import Footer from "./components/mdfooter";
import Navbar from "./components/navbarmd";

export default function MobileLayout({children}){
    return(
        <section className="md-layout">
            <Navbar/>
            <div className="md-children">
            {children}
            </div>
            <Footer/>
        </section>
    );
}